@import url("https://fonts.googleapis.com/css2?family=Poppins");

:root {
  --light: #f6f6f6;
  --dark: #161616;

  --light-rgb: 246, 246, 246;
  --dark-rgb: 22, 22, 22;

  --primary: #ac9fef;
  --secondary: #d697e3;

  --primary-rgb: 255, 127, 250;
  --secondary-rgb: 255, 127, 201;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  cursor: default;
}

* {
  font-family: "Poppins", sans-serif;
}

body {
  background-color: var(--dark);
  overflow-x: hidden;
}

.navbar {
  padding: 0px !important;
  background-color: rgba(var(--dark-rgb), 0.95) !important;
}

.nav-hidden {
  opacity: 0;
}

.nav-link {
  margin: 40px 20px !important;
  padding: 0px !important;

  font-size: 20px;
  font-weight: 600;
  letter-spacing: 5px;
  color: var(--light) !important;
  text-transform: uppercase;
  background: transparent;

  transition: 0.3s ease !important;
}

.nav-link:after {
  content: "";
  width: 0;
  height: 0;
  transition: 0.3s ease !important;
}

.nav-link:hover {
  text-shadow: 0px 0px 50px rgba(var(--primary-rgb), 1);
  color: var(--primary) !important;
}

.nav-link:hover:after {
  content: "";
  position: absolute;
  right: -5px;
  top: 35px;
  height: 50px;
  width: 60px;
  border-radius: 50%;
  transform: rotate(45deg);
  border: 0 solid var(--primary);
  text-shadow: 0px 0px 50px rgba(var(--primary-rgb), 1);
  border-width: 0 4px 0 0;
}

.primary-btn {
  font-weight: 600;
  font-size: 24px;
  text-transform: uppercase;
  letter-spacing: 3px;

  outline: none;
  border: 2px solid var(--light);
  border-radius: 50px;
  cursor: pointer;

  padding: 20px 30px;
  /* min-width: 120px;
  min-height: 75px; */

  transition: 0.3s ease;
  box-shadow: 0px 0px 50px 2px rgba(var(--primary-rgb), 0.1);
}

.primary-btn:hover {
  transform: scale(1.05);
  box-shadow: 0px 0px 50px 2px rgba(var(--secondary-rgb), 0.25);
}

.primary-btn:disabled {
  background: var(--light);
  cursor: not-allowed;
}

.primary-p {
  color: var(--light);
  font-size: 21px;
  text-align: "center";
  line-height: 40px;

  width: 40%;
}

.primary-h {
  text-transform: uppercase;
  letter-spacing: 6px;
  color: var(--light);
  font-size: 60px;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;

  margin: 0;
  user-select: none;

  transition: all 0.25s ease-out;
}

.primary-h:hover {
  letter-spacing: 8px;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.faq-container {
  width: 60%;
  margin: 40px auto;
}

.faq-item {
  color: var(--primary);
  background-color: transparent;
  overflow: hidden;
  margin: 45px;
  border: 3px solid var(--light);
  border-radius: 50px;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  -o-transition: 0.3s ease;
  transition: 0.3s ease;
}

.faq-item:hover {
  box-shadow: 0px 0px 30px 2px rgba(var(--primary-rgb), 0.25);
}

.faq-label {
  position: relative;
  margin: 0;
  display: block;
  width: 100%;
  padding: 1em;
  letter-spacing: 0.5px;
  color: var(--primary);
  font-size: 26px;
  font-weight: 600;
  cursor: pointer;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  -o-transition: 0.3s ease;
  transition: 0.3s ease;
}

.faq-text {
  padding: 0px 15px;
  margin: 0px 0;
  width: 100% !important;
  max-height: 0;
  overflow: hidden;
  z-index: -1;
  position: relative;
  opacity: 0;
  color: var(--light);
  font-size: 20px;
  -webkit-transition: 0.2s ease;
  -moz-transition: 0.2s ease;
  -o-transition: 0.2s ease;
  transition: 0.2s ease;
}

.faq-title:checked ~ .faq-text {
  max-height: 600px;
  opacity: 1;
  z-index: 0;
  padding: 15px;
  -webkit-transition: 0.2s ease;
  -moz-transition: 0.2s ease;
  -o-transition: 0.2s ease;
  transition: 0.2s ease;
}

.faq-item:hover .faq-title:checked {
  box-shadow: 0px 0px 30px 2px rgba(var(--primary-rgb), 0.25);
}

.faq-title:checked ~ .faq-label {
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  -o-transition: 0.3s ease;
  transition: 0.3s ease;
  padding-bottom: 0em;
}

.faq-icon {
  position: absolute;
  margin-top: 15px;
  margin-left: 30px;
  z-index: 5;
  font-size: 3em;
  line-height: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  -o-transition: 0.3s ease;
  transition: 0.3s ease;
}

.faq-title:checked ~ .faq-icon {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.faq-title {
  display: none;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar {
  width: 10px;
  background-color: rgba(var(--dark-rgb), 0.1);
}

::-webkit-scrollbar-thumb {
  background-color: rgba(var(--light-rgb), 0.8);
  border-radius: 50px;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

.social-menu ul {
  display: flex;
  justify-content: center;
}

.social-menu ul li {
  list-style: none;
  margin: 0;
  color: var(--secondary);
}

.social-menu ul li .fab {
  font-size: 24px;
  line-height: 48px;
  transition: 0.2s;
}

.social-menu ul li a {
  position: relative;
  display: block;
  width: 50px;
  height: 50px;
  color: var(--secondary);
  transition: 0.1s;
}

.social-menu ul li a:hover {
  transform: translateY(-3px);
  color: var(--primary);
  transition: 0.1s;
}

.mint-container {
  margin-top: 30px;
  padding: 120px 80px;

  background: var(--light);
  border-radius: 50px;

  color: var(--dark);
}

.mint-container h1 {
  font-size: 42px;
}

.mint-container .quantity-btn {
  display: inline-block;
  margin: 0px;
  width: 50px;
  height: 50px;
  text-align: center;
  outline: none;
  background: var(--light);
  border: 2px solid var(--dark);
  border-radius: 50px;
  vertical-align: middle;
  font-size: 20px;
  padding: 8px 0;
  margin: 10px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: 0.2s ease;
}

.mint-container .quantity-btn:hover {
  background: var(--primary);
}

.mint-container .quantity-btn:disabled {
  cursor: not-allowed;
}

.mint-container .quantity {
  text-align: center;
  border: 2px solid var(--dark);
  border-radius: 50px;
  background-color: var(--light);
  margin: 10px;
  font-size: 20px;
  padding-top: 8px;
  width: 50px;
  height: 50px;
}

@media only screen and (max-width: 900px) {
  .mint-container {
    padding: 45px;
    width: 90vw;
  }

  .faq-container {
    width: 90%;
  }

  .faq-icon {
    display: none;
  }

  .primary-p {
    width: 100%;
  }
}

@media only screen and (max-width: 992px) {
  .primary-h {
    font-size: 36px;
  }

  p {
    font-size: 20px !important;
  }

  html {
    overflow: scroll;
    overflow-x: hidden;
  }

  ::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
}

.roadmap-nav {
  min-height: 600px;
  margin: auto 20px;
}

.roadmap-nav a {
  font-size: 24px;
  border: none;
  background-color: var(--light);
}

.roadmap-nav-screen {
  display: block;
  max-height: 600px;
  overflow-y: scroll;
  overflow-x: hidden;
  scroll-behavior: smooth;
  position: relative;
  box-shadow: 0px 0px 100px 25px rgba(var(--dark-rgb), 0.05);
  border-radius: 50px;
}
